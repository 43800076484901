import type { JSONSchemaType } from 'ajv'

import type {
  TReport,
  TTemplate,
  TColumns,
  TIdMapItems,
  TReportType,
} from './report.types'

import COLUMN from '../../constants/column'
import REPORT_TYPE from '../../constants/reportType'

const idMap: JSONSchemaType<TIdMapItems> = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      stationId: { type: 'integer', exclusiveMinimum: 0 },
      /**
       * Note: There is a bug in ajv@8.11.1 that prevents nullable on string|null type
       * @link https://github.com/ajv-validator/ajv/issues/2163
       */
      externalId: {
        oneOf: [
          { type: 'string'} ,
          { type: 'null', nullable: true },
        ],
      },
    },
    required: ['stationId', 'externalId'],
  }
}

const columns: JSONSchemaType<TColumns> = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      key: { type: 'string', enum: Object.values(COLUMN) },
      label: { type: 'string' },
      isEnabled: { type: 'boolean' },
    },
    required: ['key', 'label', 'isEnabled'],
  },
}

const type: JSONSchemaType<TReportType> = {
  type: 'string',
  enum: Object.values(REPORT_TYPE),
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reportSchema: JSONSchemaType<TReport> = {
  type: 'object',
  properties: {
    idmap: idMap,
    columns,
    type,
  },
  required: ['idmap', 'columns'],
}

export const template: JSONSchemaType<TTemplate> = {
  type: 'object',
  properties: {
    version: { type: 'integer', minimum: 1 },
    idmap: idMap,
    columns,
    type,
  },
  required: ['version', 'idmap', 'columns'],
}
