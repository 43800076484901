import type { TAction } from '../state.types'
import type { TReport } from './report.types'
import TYPE from './report.actiontypes'

export default function reducer (state: TReport, action: TAction): TReport {
  switch (action.type) {
    case TYPE.SET_IDMAP:
      return {
        ...state,
        idmap: action.payload,
      }

    case TYPE.SET_COLUMNS:
      return {
        ...state,
        columns: action.payload,
      }

    case TYPE.SET_TYPE:
      return {
        ...state,
        type: action.payload,
      }

    case TYPE.LOAD_DATA:
      return {
        ...state,
        ...action.payload,
      }
  }

  return state
}
