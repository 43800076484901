import type { TApiContext } from '../api/ApiContext'
import type { ApiAppHttpException } from '../models/api-exception'
import type { TDateRange } from '../state/ui'
import type { TColumns, TReportType } from '../state/report'
import type { TSensorCode } from '../constants/sensor'

import SENSOR from '../constants/sensor'

import { formatDateTimeIso8601 } from '../utils/date'

export type TReportRequestPayload = {
  /**
   * Device ID
   * Note: API supports multiple stations, however
   * - outputs data only for last station (bug)
   * - there is no discriminator in the output
   */
  devicesList: [number],
  /** Sensor codes */
  sensorsList: TSensorCode[],
  /** From date in Y-m-d\\TH:i:sP (or DateTime::ISO8601) format */
  fromDate: string,
  /** To date in Y-m-d\\TH:i:sP (or DateTime::ISO8601) format */
  toDate: string,
  /** Not used; use content-type header for csv or any other for xls */
  contentType: 'csv',
  /** Mode (daily | montly) */
  reportMode: TReportType,
}

/**
 * Data provider
 * see API repo > AppBundle\Controller\Api\ReportController::csvAction
 * see API repo > AppBundle\Model\Request\Report
 */
export async function getData(
  stationId: number,
  columns: TColumns,
  dateRange: TDateRange,
  reportType: TReportType,
  api: TApiContext,
  signal?: AbortSignal,
): Promise<string|null> {
  // Convert columns to sensors list, maintain sensors order
  const sensorsList = Object.values(SENSOR)
    .filter(sensorCode => columns.find(column => column.key === sensorCode))

  if (!sensorsList.length) {
    throw new Error('Empty sensors list')
  }

  const requestBody: TReportRequestPayload = {
    devicesList: [stationId],
    sensorsList,
    fromDate: formatDateTimeIso8601(new Date(dateRange.start)),
    toDate: formatDateTimeIso8601(new Date(dateRange.end)),
    contentType: 'csv',
    reportMode: reportType,
  }

  // Fetch
  /** @throws {TypeError} */
  const response = await api.xfetch('api/report/excel', {
    method: 'POST',
    headers: { 'Content-Type': 'application/csv' },
    body: JSON.stringify(requestBody),
    signal,
  })

  const contentType = response.headers.get('Content-Type') ?? ''
  const [
    mediaType,
    charset, // eslint-disable-line @typescript-eslint/no-unused-vars
  ] = contentType.split(/;\s*/)

  // Validate status code (ie. 401 Unauthorized)
  if (!response.ok) {
    if (mediaType !== 'application/json') {
      throw new Error('Invalid API response')
    }

    const errorPayload: ApiAppHttpException = await response.json()

    // Detect empty report pseudo error
    if (
      response.status === 404 &&
      errorPayload.error_description === 'Empty report'
    ) {
      return null
    }

    // Hope it's AppBundle\Exception\AppHttpException (Error JSON envelope)
    throw new Error(`${errorPayload.error_description} (${response.status})`)
  }

  // Validate content type (text/csv; charset=UTF-8)
  if (mediaType !== 'text/csv') {
    throw new Error('Invalid API content type')
  }

  return response.text()
}
