/**
 * @see https://en.wikipedia.org/wiki/Comma-separated_values#Basic_rules
 */

type TValue = string|null

/**
 * CSV parser
 * @see https://stackoverflow.com/questions/1293147/example-javascript-code-to-parse-csv-data
 */
export function parse (
  strData: string,
  strDelimiter: string = ','
): Array<TValue[]> {
  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    (
      // Delimiters.
      `(\\${strDelimiter}|\\r?\\n|\\r|^)` +
      // Quoted fields.
      `(?:"([^"]*(?:""[^"]*)*)"|` +
      // Standard fields.
      `([^\\${strDelimiter}\\r\\n]*))`
    ),
    'gi'
  )

  // Create an array to hold our data. Give the array
  // a default empty first row.
  const arrData: Array<TValue[]> = [[]]

  // Create an array to hold our individual pattern
  // matching groups.
  // let arrMatches: [string, string, string|undefined, string]|null
  let arrMatches: string[]|null = null

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      match,
      delimiter,
      quotedValue,
      value,
    ]: (string|undefined)[] = arrMatches

    // Get the delimiter that was found.
    const strMatchedDelimiter = delimiter

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([])
    }

    let strMatchedValue: string|null = null

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (quotedValue) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = quotedValue.replace(
        new RegExp('""', 'g'),
        '"'
      )
    } else if (value !== '') {
      // We found a non-quoted value.
      strMatchedValue = value
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[ arrData.length - 1 ].push( strMatchedValue )
  }

  // Return the parsed data.
  return arrData
}

/**
 * CSV Stringifier
 */
export function stringify (
  table: Array<unknown[]>,
  strDelimiter: string = ','
): string {
  const outputRows: string[] = []

  // Iterate over rows
  for (const rows of table) {
    const outputRow: TValue[] = []

    // Interate over cells
    for (const cell of rows) {
      // Detect type
      switch (typeof cell) {
        // String
        case 'string':
          outputRow.push(
            `"${cell.replaceAll('"', '""')}"`
          )
          break

        // Number
        case 'number':
          outputRow.push(
            cell.toString()
          )
          break

        // undefined, boolean, bigint, symbol, function, object
        default:
          outputRow.push(
            null
          )
          break
      }
    }

    outputRows.push(
      outputRow.join(strDelimiter)
    )
  }

  return outputRows.join('\n')
}
