import {
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import type { SelectCustomEvent } from '@ionic/react'

import type { TReportType } from '../../state/report'
import REPORT_TYPE from '../../constants/reportType'

type TReportTypeOption = {
  value: TReportType,
  label: string,
}

const reportTypeOptions: TReportTypeOption[] = [
  { value: REPORT_TYPE.DAILY,  label: 'Daily averages' },
  { value: REPORT_TYPE.HOURLY, label: 'Hourly averages' },
]

/**
 * Controlled report type picker
 */
const ReportType: preact.FunctionComponent<{
  value: TReportType,
  onChange: (value: TReportType) => void,
}> = ({
  value,
  onChange,
}) => {
  /**
   * Handle value change
   */
  const handleChange = (event: SelectCustomEvent<TReportType>): void =>
    onChange(event.detail.value)

  return (
    <IonSelect
      interfaceOptions={{ header: 'Report Type' }}
      interface="alert"
      value={value}
      label="Type"
      onIonChange={handleChange}
    >
      {reportTypeOptions.map(reportType =>
        <IonSelectOption
          key={reportType.value}
          value={reportType.value}
        >
          {reportType.label}
        </IonSelectOption>
      )}
    </IonSelect>
  )
}

export default ReportType
