import { render } from 'preact'

import App from './components/App/App'

import './index.css'

const urlSearchParams = new URLSearchParams(window.location.search)

// Use Mock Service Worker
if (
  // ..only on development env
  import.meta.env.MODE === 'development' &&
  // ..when mocks are not disabled
  !urlSearchParams.has('disable-mocks')
) {
  import('./mocks/browser')
    .then(({ worker }) => worker.start({
      // Warn about unhandled requests to API, ignore rest (assets, chrome-extension)
      onUnhandledRequest(request, print) {
        if (request.url.href.startsWith(import.meta.env.VITE_API_URL)) {
          print.warning()
        }
      },
    }))
}

render(
  <App env={import.meta.env} />,
  document.getElementById('app')!
)
