import { createContext } from 'preact'
import { useState, useRef, useEffect, useContext, useCallback } from 'preact/hooks'

import type { TCredentials } from '../models/credentials'
import type {
  TOauthParams,
  TOauthSuccessResponse,
  TOauthErrorResponse,
} from '../models/oauth'
import OAuth2Error from '../utils/oauth2-error'

/**
 * API API
 */
export type TApiContext = {
  readonly isAuthenticated: boolean,
  xfetch: (relativeUrl: string, init: RequestInit) => Promise<Response>,
  signIn: (credentials: TCredentials) => Promise<TOauthSuccessResponse>,
  signOut: () => void,
}

/**
 * API context object
 */
export const ApiContext = createContext<TApiContext>({
  isAuthenticated: false,
  xfetch: () => Promise.resolve(new Response()),
  signIn: () => Promise.resolve({
    access_token: '',
    expires_in: 3600,
    refresh_token: '',
    scope: null,
    token_type: 'Bearer',
  }),
  signOut: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 * API hook
 */
export function useApi() {
  return useContext(ApiContext)
}

/**
 * API context provider
 */
export const ApiProvider: preact.FunctionComponent<{
  env: ImportMetaEnv,
}> = ({
  env,
  children,
}) => {
  // Create auth state with proxy to access in API functions
  const [ auth, _setAuth ] = useState<TOauthSuccessResponse | null>(null)
  const authRef = useRef<TOauthSuccessResponse | null>(auth)
  const setAuth = (freshAuth: TOauthSuccessResponse | null) =>
    _setAuth(authRef.current = freshAuth)

  /**
   * Authenticate either with credentials or refresh token and update auth state
   */
  const authenticate = useCallback(
    async (params: TOauthParams): Promise<TOauthSuccessResponse> => {
      const urlSearchParams = new URLSearchParams({
        client_id:     env.VITE_API_CLIENT_ID,
        client_secret: env.VITE_API_CLIENT_SECRET,
        ...params,
      })

      const response = await fetch(`${env.VITE_API_URL}/oauth/v2/token?${urlSearchParams}`)
      const responseJson: TOauthSuccessResponse | TOauthErrorResponse = await response.json()

      if (!isAuthSuccessResponse(response, responseJson)) {
        throw new OAuth2Error(responseJson)
      }

      setAuth(responseJson)

      return responseJson
    },
    [env.VITE_API_URL, env.VITE_API_CLIENT_ID, env.VITE_API_CLIENT_SECRET]
  )

  /**
   * Extended fetch which retries request with new auth on failure
   */
  const xfetch = async (relativeUrl: string, init: RequestInit = {}): Promise<Response> => {
    const url = `${env.VITE_API_URL}/${relativeUrl}`
    const headers = new Headers(init.headers)

    const auth = authRef.current

    if (auth) {
      headers.set('Authorization', `${auth.token_type} ${auth.access_token}`)
    }

    const response = await fetch(url, { ...init, headers })

    // Detect expired acces token
    if (auth && response.status === 401) {
      const responseJson: TOauthErrorResponse = await response.clone().json()

      // Request new access token with refresh token
      if (responseJson.error === 'invalid_grant') {
        // Use reference if auth has been auto-refreshed meanwhile
        const freshAuth = authRef.current && authRef.current !== auth
          ? authRef.current
          : await refresh(auth)

        // Not: cannot reuse xfetch as there is no access to current auth state
        headers.set('Authorization', `${freshAuth.token_type} ${freshAuth.access_token}`)

        // Try again, this time without retry allowing failures
        return fetch(url, { ...init, headers })
      }
    }

    return response
  }

  /**
   * Refresh token
   */
  const refresh = useCallback(
    async (auth: TOauthSuccessResponse): Promise<TOauthSuccessResponse> =>
      authenticate({
        grant_type:    'refresh_token',
        refresh_token: auth.refresh_token,
      }),
    [authenticate]
  )

  /**
   * Sign in
   */
  const signIn = async (credentials: TCredentials): Promise<TOauthSuccessResponse> =>
    authenticate({
      grant_type: 'password',
      password:   credentials.password,
      username:   credentials.username,
    })

  /**
   * Sign out
   */
  const signOut = () =>
    setAuth(null)

  /**
   * Proactively refresh auth before it expires (360s before 1h validity)
   * However not more often than 1 min
   * Restart on new auth
   * Warning: Race condition with xfetch
   */
  useEffect(() => {
    if (auth) {
      const refreshTimeoutId = window.setTimeout(
        () => refresh(auth),
        Math.max(auth.expires_in * .9e3, 60e3)
      )

      return () => window.clearTimeout(refreshTimeoutId)
    }
  }, [refresh, auth])

  return (
    <ApiContext.Provider value={{
      isAuthenticated: auth !== null,
      xfetch,
      signIn,
      signOut,
    }}>
      {children}
    </ApiContext.Provider>
  )
}

/**
 * TS Type guard
 */
function isAuthSuccessResponse (authResponse: Response, newAuth: TOauthSuccessResponse | TOauthErrorResponse): newAuth is TOauthSuccessResponse {
  return authResponse.ok
}
