import type { ToastOptions } from '@ionic/react'
import { useIonToast } from '@ionic/react'

type PartialToastOptions = Partial<Omit<ToastOptions, 'message'>>

/**
 * Show ionic toast with default options
 * @link https://ionicframework.com/docs/api/toast
 */
export default function useToast(defaultOptions?: PartialToastOptions) {
  const [ present, dismiss ] = useIonToast()

  return (message: string, options?: PartialToastOptions) =>
    present({
      buttons: [{
        text: 'Hide',
        handler: dismiss,
      }],
      duration: 5e3,
      color: undefined,
      ...defaultOptions,
      ...options,
      message,
    })
}
