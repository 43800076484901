/**
 * Load/ Save implemented with File System Access API (chrome 91+ & https)
 * @link https://web.dev/file-system-access/
 */
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonIcon,
} from '@ionic/react'
import {
  cloudDownloadSharp,
  cloudDownloadOutline,
  cloudUploadSharp,
  cloudUploadOutline,
} from 'ionicons/icons'

import { validateTemplate } from '../../state/validation'
import type { TTemplate } from '../../state/report'
import { loadData as loadReportData } from '../../state/report'
import useToast from '../../hooks/useToast'
import { useAppState } from '../../state/AppStateContext'
import { setTplFileHandle } from '../../state/ui'

/**
 * Detect browser support
 */
export const isShowFilePickerSupported: boolean =
  'showOpenFilePicker' in window &&
  'showSaveFilePicker' in window

/**
 * Common options for showOpenFilePicker/ showSaveFilePicker
 */
const commonFileHandleOptions: FilePickerOptions = {
  // @ts-expect-error N/A in types yet
  id: 'syngeos-report-template',
  startIn: 'documents',
  types: [
    {
      description: 'Syngeos reports template',
      accept: {
        'application/json': ['.srt.json'], // Note: may use 'application/syngeos-report-template+json'
      },
    },
  ],
}

/**
 * Export/ import state as a template
 */
const Template: preact.FunctionComponent = () => {
  // State
  const { state, dispatch } = useAppState()

  // Toast
  const showToast = useToast()

  /**
   * Handle load click
   */
  const handleOpenButtonClick = async (isRevert: boolean = false) => {
    let file: File

    // Workaround when File System Access API is N/A
    if (!isShowFilePickerSupported) {
      try {
        file = await uploadContent()
      // Detect dialog cancel
      } catch {
        return
      }
    } else {
      let tmpFileHandle: FileSystemFileHandle

      // Reuse existing
      if (isRevert && state.ui.tplFileHandle) {
        tmpFileHandle = state.ui.tplFileHandle
      // Create new file handle
      } else {
        try {
          [tmpFileHandle] = await window.showOpenFilePicker({
            ...commonFileHandleOptions,
            // @ts-expect-error N/A in types yet
            startIn: state.fileHandle ?? commonFileHandleOptions.startIn,
            multiple: false,
          })
        } catch (domException) {
          // DOMException: The user aborted a request.
          return
        }

        dispatch(setTplFileHandle(tmpFileHandle))
      }

      file = await tmpFileHandle.getFile()
    }

    const contents = await file.text()

    let templateData: TTemplate

    try {
      templateData = JSON.parse(contents)
    } catch (syntaxError) {
      showToast('Error in template file', { color: 'warning' })
      return
    }

    // Validate and sanitize using schema (note: mutates data)
    if (!validateTemplate(templateData)) {
      showToast('Invalid template schema', { color: 'warning' })
      return
    }

    const { version, ...report } = templateData

    // Check supported versions
    if (version !== 1) {
      showToast('Invalid template version', { color: 'warning' })
      return
    }

    dispatch(loadReportData(report))

    showToast(`Template loaded from ${file.name}`, { color: 'success' })
  }

  /**
   * Handle save as click
   */
  const handleSaveButtonClick = async (isSaveAs: boolean = false) => {
    const { report, _persist } = state
    const templateData = {
      version: _persist.version,
      ...report,
    }

    // Validate and sanitize using schema (note: mutates data)
    if (!validateTemplate(templateData)) {
      showToast('Invalid template schema', { color: 'warning' })
      return
    }

    const contents = JSON.stringify(templateData, undefined, 2)
    const suggestedName = 'syngeos-report-template.srt.json'

    // Workaround when File System Access API is N/A
    // Note: Misleading as handler may be triggered by Save As.. button
    if (!isShowFilePickerSupported) {
      downloadContent(contents, suggestedName)
      return
    }

    let tmpFileHandle: FileSystemFileHandle

    // Create new file handle
    if (isSaveAs || !state.ui.tplFileHandle) {
      try {
        tmpFileHandle = await window.showSaveFilePicker({
          ...commonFileHandleOptions,
          // @ts-expect-error N/A in types yet
          startIn: state.fileHandle ?? commonFileHandleOptions.startIn,
          suggestedName,
        })
      } catch (domException) {
        // DOMException: The user aborted a request.
        return
      }

      dispatch(setTplFileHandle(tmpFileHandle))
    // Reuse existing
    } else {
      tmpFileHandle = state.ui.tplFileHandle
    }

    await writeFile(tmpFileHandle, contents)

    showToast(`Template saved to ${tmpFileHandle.name}`, { color: 'success' })
  }

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>
          {'Template'}
        </IonLabel>
      </IonListHeader>
      {/** Open… */}
      <IonItem
        button={true}
        onClick={() => handleOpenButtonClick(false)}
      >
        <IonIcon
          md={cloudUploadSharp}
          ios={cloudUploadOutline}
          slot="start"
        />
        {'Open…'}
      </IonItem>

      {/** Save */}
      {/*
      <IonItem
        button={true}
        disabled={!state.fileHandle}
        onClick={() => handleSaveButtonClick(false)}
      >
        <IonIcon
          md={cloudDownloadSharp}
          ios={cloudDownloadOutline}
          slot="start"
        />
        {'Save'}
      </IonItem>
      */}

      {/** Save As… */}
      <IonItem
        button={true}
        onClick={() => handleSaveButtonClick(true)}
        lines="none"
      >
        <IonIcon
          md={cloudDownloadSharp}
          ios={cloudDownloadOutline}
          slot="start"
        />
        {'Save As…'}
      </IonItem>

      {/** Revert (reload) */}
      {/*
      <IonItem
        button={true}
        disabled={!state.fileHandle}
        lines="none"
        onClick={() => handleOpenButtonClick(true)}
      >
        <IonIcon
          md={cloudUploadSharp}
          ios={cloudUploadOutline}
          slot="start"
        />
        {'Revert'}
      </IonItem>
      */}
    </IonList>
  )
}

export default Template

/**
 * Write contents to file handle
 */
async function writeFile(fileHandle: FileSystemFileHandle, contents: string): Promise<void> {
  // Create a FileSystemWritableFileStream to write to.
  const writable = await fileHandle.createWritable()
  // Write the contents of the file to the stream.
  await writable.write(contents)
  // Close the file and write the contents to disk.
  await writable.close()
}

/**
 * Upload file
 * @link https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
 * @link https://stackoverflow.com/a/63773257/1012616
 */
function uploadContent(): Promise<File> {
  return new Promise((resolve, reject) => {
    const inputElement = document.createElement('input')

    inputElement.type = 'file'
    inputElement.accept = 'application/json'

    // Change triggered
    let isChanged = false

    inputElement.addEventListener('change', () => {
      isChanged = true

      inputElement.files && inputElement.files.length
        ? resolve(inputElement.files[0])
        : reject()
      },
      { once: true }
    )

    // Detect cancel and reject when input change event didn't fire in meanwhile
    window.addEventListener('focus', () =>
      setTimeout(() =>
        isChanged || reject(),
        300
      ),
      { once: true }
    )

    inputElement.click()
  })
}

/**
 * Download content immediately (no 'Save As…' dialog)
 */
function downloadContent(contents: string, filename: string): void {
  const blob = new Blob([contents], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  const anchorElement = document.createElement('a')

  anchorElement.href = url
  anchorElement.download = filename

  anchorElement.click()

  // Clean up
  // Note: Qhwen using events, browser cancels download with a 'Failed: Network error'
  window.setTimeout(() =>
    URL.revokeObjectURL(url),
    0
  )
}
