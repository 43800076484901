import type { TReport } from './report.types'

import COLUMN from '../../constants/column'
import REPORT_TYPE from '../../constants/reportType'

const initialState: TReport = {
  idmap: [
    { stationId:  200, externalId:  '59' },
    { stationId: 1020, externalId:  '66' },
    { stationId:  298, externalId:  '67' },
    { stationId:  526, externalId: '118' },
    { stationId:  519, externalId: '121' },
  ],
  columns: [
    { key: COLUMN.LABEL,        label: 'school_id',    isEnabled: true, },
    { key: COLUMN.DATE,         label: 'date_trunc',   isEnabled: true, },
    { key: COLUMN.PM2_5,        label: 'pm2,5',        isEnabled: true, },
    { key: COLUMN.PM10,         label: 'pm10',         isEnabled: true, },
    { key: COLUMN.PM1,          label: 'pm1',          isEnabled: false, },
    { key: COLUMN.SO2,          label: 'so2',          isEnabled: false, },
    { key: COLUMN.NO2,          label: 'no2',          isEnabled: false, },
    { key: COLUMN.CO,           label: 'co',           isEnabled: false, },
    { key: COLUMN.O3,           label: 'o3',           isEnabled: false, },
    { key: COLUMN.C6H6,         label: 'c6h6',         isEnabled: false, },
    { key: COLUMN.TEMPERATURE,  label: 'temperature',  isEnabled: false, },
    { key: COLUMN.AIR_PRESSURE, label: 'air_pressure', isEnabled: false, },
    { key: COLUMN.HUMIDITY,     label: 'humidity',     isEnabled: false, },
    { key: COLUMN.CAQI,         label: 'caqi',         isEnabled: false, },
  ],
  type: REPORT_TYPE.DAILY,
}

export default initialState
