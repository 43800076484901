import SENSOR from './sensor'

import type { TSensorCode } from './sensor'

export type TColumnCode =
  'label' |
  'date' |
  TSensorCode

/**
 * @see AppBundle\Controller\Api\ReportController::csvAction
 * @link https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
 * @note Do not change, enums are persisted
 */
const COLUMN: Record<string, TColumnCode> = {
  LABEL        : 'label',
  DATE         : 'date', // Data i godzina
  PM2_5        : SENSOR.PM2_5,
  PM10         : SENSOR.PM10,
  PM1          : SENSOR.PM1,
  SO2          : SENSOR.SO2,
  NO2          : SENSOR.NO2,
  CO           : SENSOR.CO,
  O3           : SENSOR.O3,
  C6H6         : SENSOR.C6H6,
  TEMPERATURE  : SENSOR.TEMPERATURE,
  AIR_PRESSURE : SENSOR.AIR_PRESSURE,
  HUMIDITY     : SENSOR.HUMIDITY,
  CAQI         : SENSOR.CAQI,
} as const

export default COLUMN
