import { useState } from 'preact/hooks'
import mergeWith from 'lodash.mergewith'

/**
 * Use local storage hook
 * @param {string} key - Key to use for local storage item
 * @param {Object} [initialValue] - Initial value
 * @param {string[]} [exclude] - Object keys to exclude when saving
 * @see https://usehooks.com/useLocalStorage/
 */
export default function useLocalStorage<
  TData extends Record<string, unknown>
>(
  key: string,
  initialValue: TData | null = null,
  options: { exclude: Array<keyof TData> } = { exclude: [] },
): [
  TData,
  (value: TData) => void,
] {
  const [ storedValue, setStoredValue ] = useState<TData>(() => {
    const localStorageValue = localStorage.getItem(key)

    let data

    // Decode, catching SyntaxError
    if (localStorageValue) {
      try { data = JSON.parse(localStorageValue) } catch {}
    }

    // Deep merge initial data and stored data, overwriting arrays
    return mergeWith({}, initialValue, data, (objValue: unknown, srcValue: unknown) =>
      Array.isArray(srcValue) ? srcValue : undefined
    )
  })

  const setValue = (value: TData): void => {
    setStoredValue(value)

    const entries = Object.entries(value)
    const storeValue = Object.fromEntries(entries.filter(([ key ]) =>
      !options.exclude.includes(key)
    ))

    localStorage.setItem(key, JSON.stringify(storeValue))
  }

  return [
    storedValue,
    setValue,
  ]
}
