import { createContext } from 'preact'
import { useEffect, useContext, useReducer } from 'preact/hooks'

import type { TAppState, TAction } from './state.types'
import useLocalStorage from '../hooks/useLocalStorage'

import { initialState, rootReducer } from './state'

/**
 * App state API
 */
type TAppStateContext = {
  state: TAppState,
  dispatch: (action: TAction) => void,
}

/**
 * App state object
 */
export const AppStateContext = createContext<TAppStateContext>({
  state: initialState,
  dispatch: () => undefined,
})

/**
 * App state hook
 */
export function useAppState() {
  return useContext(AppStateContext)
}

/**
 * App state context provider
 */
export const AppStateProvider: preact.FunctionComponent = ({
  children,
}) => {
  // Load data
  // TODO: Validate against schema
  const [ storedState, setStoredState ] = useLocalStorage<TAppState>('syngeos-reports:persist', initialState, {
    exclude: ['ui'],
  })

  // Hook up root reducer
  const [ state, dispatch ] = useReducer<TAppState, TAction>(rootReducer, storedState)

  // Update stored state on state change
  useEffect(() => {
    if (state !== storedState) {
      setStoredState(state)
    }
  }, [state, storedState, setStoredState])

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  )
}
