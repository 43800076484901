import './Logo.css'

const Logo: preact.FunctionComponent<{
  /** Apply styles for position inside ion-toolbar */
  toolbar?: boolean,
}> = ({
  toolbar = false,
}) =>
  <img
    className={`syn-logo${toolbar ? ' syn-logo--toolbar' : ''}`}
    src="assets/icon/icon.svg"
    alt="Syngeos"
  />

export default Logo
