/**
 * Cheatsheet of type IDs on prod server
 *
 * type_id | slug
 *    ---: | :---
 *       1 | temperature
 *       2 | humidity
 *       3 | air_pressure
 *       4 | pm1
 *       5 | pm2_5
 *       6 | pm10
 *       7 | co
 *       8 | no2
 *       9 | so2
 *      10 | o3
 *      11 | c6h6
 *      12 | caqi
 *      13 | noise
 *      14 | soil_vwc
 *      15 | soil_temperature
 *      16 | soil_ap
 *      17 | ch20
 */

export type TSensorCode =
 'pm2_5' |
 'pm10' |
 'pm1' |
 'so2' |
 'no2' |
 'co' |
 'o3' |
 'c6h6' |
 'temperature' |
 'air_pressure' |
 'humidity' |
 'caqi' |
 'light_intensity'

const SENSOR: Record<string, TSensorCode> = {
  PM2_5        : 'pm2_5',
  PM10         : 'pm10',
  PM1          : 'pm1',
  SO2          : 'so2',
  NO2          : 'no2',
  CO           : 'co',
  O3           : 'o3',
  C6H6         : 'c6h6',
  TEMPERATURE  : 'temperature',
  AIR_PRESSURE : 'air_pressure',
  HUMIDITY     : 'humidity',
  CAQI         : 'caqi',
  LIGHT_INTENSITY: 'light_intensity',
} as const

export default SENSOR
