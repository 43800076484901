import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
} from '@ionic/react'

import {
  closeSharp,
  closeOutline,
  saveSharp,
  saveOutline,
} from 'ionicons/icons'

/**
 * Modal Wrapper
 */
const Modal: preact.FunctionComponent<{
  title: string,
  onSave: () => void,
  onCancel: () => void,
}> = ({
  children,
  title,
  onSave,
  onCancel,
}) => (
  <>
    {/** Modal toolbar */}
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          {title}
        </IonTitle>
        <IonButtons slot="secondary">
          {/** Close */}
          <IonButton onClick={() => onCancel()}>
            <IonIcon
              slot="icon-only"
              md={closeSharp}
              ios={closeOutline}
            />
          </IonButton>
          {/** Save */}
          <IonButton onClick={() => onSave()}>
            <IonIcon
              slot="icon-only"
              md={saveSharp}
              ios={saveOutline}
            />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>

    {/** Modal body */}
    <IonContent className="ion-padding">
      {children as React.ReactNode}
    </IonContent>
  </>
)


export default Modal
