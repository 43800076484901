import type {
  TReport,
  TColumns,
  TIdMapItems,
  TReportType,
} from './report.types'
import TYPE from './report.actiontypes'

export const setIdMap = (entries: TIdMapItems) => ({
  type: TYPE.SET_IDMAP as const,
  payload: entries,
})

export const setColumns = (columns: TColumns) => ({
  type: TYPE.SET_COLUMNS as const,
  payload: columns,
})

export const setReportType = (type: TReportType) => ({
  type: TYPE.SET_TYPE as const,
  payload: type,
})

export const loadData = (data: TReport) => ({
  type: TYPE.LOAD_DATA as const,
  payload: data,
})

export type TReportAction =
  ReturnType<typeof setIdMap> |
  ReturnType<typeof setColumns> |
  ReturnType<typeof setReportType> |
  ReturnType<typeof loadData>
