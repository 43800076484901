import {
  IonButton,
  IonIcon,
  IonPopover,
  IonContent,
} from '@ionic/react'

import {
  ellipsisHorizontal,
  ellipsisVertical,
} from 'ionicons/icons'

/**
 * Popover wrapper
 */
const PopoverWrapper: preact.FunctionalComponent<{
  id: string,
}> = ({
  id,
  children,
}) =>
  <>
    <IonButton
      id={id}
      size="small"
      fill="default"
      className="ion-no-margin ion-float-right"
    >
      <IonIcon
        slot="icon-only"
        ios={ellipsisHorizontal}
        md={ellipsisVertical}
      />
    </IonButton>

    <IonPopover
      showBackdrop={false}
      trigger={id}
      dismissOnSelect={true}
      reference="trigger"
    >
      <IonContent>
        {children as React.ReactNode}
      </IonContent>
    </IonPopover>
  </>

export default PopoverWrapper
