import { IonApp, setupIonicReact } from '@ionic/react'

import { ApiProvider, useApi } from '../../api/ApiContext'
import { AppStateProvider } from '../../state/AppStateContext'

import DashboardPage from '../../pages/Dashboard/DashboardPage'
import SignInPage from '../../pages/SignIn/SignInPage'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import '../../theme/variables.css'

setupIonicReact()

const App: preact.FunctionComponent = () => {
  const { isAuthenticated } = useApi()

  return isAuthenticated
    ? <DashboardPage />
    : <SignInPage />
}

const AppContextProviders: preact.FunctionComponent<{
  env: ImportMetaEnv,
}> = ({
  env,
}) => (
  <IonApp>
    <AppStateProvider>
      <ApiProvider env={env}>
        <App />
      </ApiProvider>
    </AppStateProvider>
  </IonApp>
)

export default AppContextProviders
