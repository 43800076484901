import type { TDateRange, TTplFileHandle } from './ui.types'
import TYPE from './ui.actiontypes'

export const setDateRange = (dateRange: TDateRange) => ({
  type: TYPE.SET_DATERANGE as const,
  payload: dateRange,
})

export const setTplFileHandle = (fileHandle: TTplFileHandle) => ({
  type: TYPE.SET_TPLFILEHANDLE as const,
  payload: fileHandle,
})

export type TUiAction =
  ReturnType<typeof setDateRange> |
  ReturnType<typeof setTplFileHandle>
