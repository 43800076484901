import COLUMN from '../constants/column'

import type { TColumn, TColumnKey } from '../state/report'

type TInputRow = Array<string|null>
type TOuputValue = string|number|null
type TOutputRow = Array<TOuputValue>

export type TOutputRows = TOutputRow[]

/**
 * Convert input to output
 */
export default function convert (
  columns: TColumn[],
  externalId: string,
  inputHeaders: string[],
  inputRows: TInputRow[],
): TOutputRows {
  // Create array of indexed column keys
  const inputColumnKeys = inputHeaders.map((inputKey: string | TColumnKey, index: number) =>
    index === 0
      ? COLUMN.DATE
      : inputKey
  )

  return inputRows.map((inputRow): TOutputRow =>
    columns.map((column): TOuputValue => {
      // Label
      if (column.key === COLUMN.LABEL) {
        return externalId
      }

      // Get column index in input array
      const inputColumnIndex = inputColumnKeys.indexOf(column.key)

      if (inputColumnIndex === -1) {
        return null
      }

      // Parse value
      const rawValue = inputRow[inputColumnIndex]

      // String
      if (column.key === COLUMN.DATE) {
        return rawValue
      }

      // Null
      if (rawValue === null) {
        return rawValue
      }

      // String, numeric
      return Number.parseFloat(rawValue)
    })
  )
}
