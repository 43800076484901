import type { TOauthErrorResponse } from '../models/oauth'

/**
 * OAuth2 error
 */
export default class OAuth2Error extends Error {
  /**
   * Get (english) description
   */
  get description(): string {
    return this.oauth2ErrorResponse.error_description
  }

  /**
   * @inheritdoc
   */
  constructor(protected oauth2ErrorResponse: TOauthErrorResponse) {
    super(oauth2ErrorResponse.error_description)

    // Set stack trace
    Error.captureStackTrace(this, this.constructor)

    /**
     * Set the prototype explicitly for ES5 target.
     * https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
     */
    Object.setPrototypeOf(this, OAuth2Error.prototype)
  }
}

OAuth2Error.prototype.name = 'OAuth2Error'
