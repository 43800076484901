import {
  IonButton,
  IonIcon,
} from '@ionic/react'
import {
  downloadSharp,
  downloadOutline,
} from 'ionicons/icons'

/**
 * Download button component.
 * Problem with using plain Anchor element is that it's not possible to use URL.revokeObjectURL in click handler as it disables download action
 * Another workaround would be to use setTimeout(, 0)
 */
const DownloadButton: preact.FunctionComponent<{
  /** Data */
  data: string | null,
  /** File name */
  filename: string,
  /** Click handler */
  onClick?: () => void,
}> = ({
  data,
  filename,
  onClick,
}) => {
  /**
   * Handle button click
   */
  const handleClick = (event: React.MouseEvent): void => {
    if (!data) {
      return
    }

    event.preventDefault()

    const anchorElement = document.createElement('a')

    anchorElement.href = data
    anchorElement.download = filename

    anchorElement.click()

    onClick && onClick()
  }

  return (
    <IonButton
      type="button"
      disabled={!data}
      onClick={handleClick}
    >
      <IonIcon
        md={downloadSharp}
        ios={downloadOutline}
        slot="start"
      />
      {'Download'}
    </IonButton>
  )
}

export default DownloadButton
