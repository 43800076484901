/** Pad date time part */
const pad = (value: number): string => value
  .toString()
  .padStart(2, '0')

/**
 * Format date to ISO8601 including time zone offset
 * Y-m-d\\TH:i:sP
 * @link https://ionicframework.com/docs/api/datetime#time-zones
 * @link https://stackoverflow.com/a/17415677/1012616
 */
export function formatDateTimeIso8601(date: Date): string {
  const timeZoneOffset = - date.getTimezoneOffset()
  const dif = timeZoneOffset >= 0 ? '+' : '-'

  // eslint-disable-next-line prefer-template
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(timeZoneOffset) / 60)) +
    ':' + pad(Math.abs(timeZoneOffset) % 60)
}
