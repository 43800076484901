import type { TAppState, TAction } from './state.types'

import { initialState as report, reportReducer } from './report'
import { initialState as settings, settingsReducer } from './settings'
import { initialState as ui, uiReducer } from './ui'
import { initialState as _persist, persistReducer } from './persist'

export const initialState: TAppState = {
  report,
  settings,
  ui,
  _persist,
}

/**
 * Combined reducers
 */
export const rootReducer = (state: TAppState, action: TAction): TAppState => {
  // Update state from persistent storage
  state = persistReducer(state, action)

  return {
    ...state,
    report: reportReducer(state.report, action),
    settings: settingsReducer(state.settings, action),
    ui: uiReducer(state.ui, action),
  }
}
