import type { TAction } from '../state.types'
import type { TUiState } from './ui.types'
import TYPE from './ui.actiontypes'

export default function reducer (state: TUiState, action: TAction): TUiState {
  switch (action.type) {
    case TYPE.SET_DATERANGE:
      return {
        ...state,
        dateRange: action.payload,
      }

    case TYPE.SET_TPLFILEHANDLE:
      return {
        ...state,
        tplFileHandle: action.payload,
      }
  }

  return state
}
