import { useState, useEffect, useRef } from 'preact/hooks'
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonProgressBar,
  IonText,
} from '@ionic/react'
import type { InputCustomEvent } from '@ionic/react'
import { version as appVersion, description as appDescription } from '../../../package.json'

import type { TCredentials } from '../../models/credentials'
import OAuth2Error from '../../utils/oauth2-error'
import { useApi } from '../../api/ApiContext'

import Logo from '../../components/Logo/Logo'
import Footer from '../../components/Footer/Footer'

import './SignInPage.css'

const SignInPage: preact.FunctionComponent = () => {
  const [ credentials, setCredentials ] = useState<TCredentials>({
    username: '',
    password: '',
  })

  const api = useApi()

  const [ error, setError ] = useState<Error | null>(null)
  const [ isAuthenticating, setIsAuthenticating ] = useState<boolean>(false)

  const formRef = useRef<HTMLFormElement>(null)
  const [ submitButtonTabIndex, setSubmitButtonTabIndex ] = useState<number>(0)

  // Set tabIndex on form elements manually, as when using on IonInputs attribute is duplicated
  useEffect(() => {
    if (formRef.current && !submitButtonTabIndex) {
      let tabIndexIterator = submitButtonTabIndex

      for (const element of formRef.current.elements) {
        if (element instanceof HTMLElement) {
          element.tabIndex = tabIndexIterator++
        }
      }

      // This has no effect, but at least triggers render
      setSubmitButtonTabIndex(tabIndexIterator)
    }
  }, [submitButtonTabIndex])

  /**
   * Handle username change
   * Bug: autofill tiggers event with an empty string when value prop is present
   * @see https://github.com/ionic-team/ionic-framework/issues/23335
   */
  const handleUsernameChange = async (event: InputCustomEvent) => {
    // Browser autofill bug workaround
    const nativeInput = await event.target.getInputElement()

    setCredentials({
      ...credentials,
      username: event.detail.value || nativeInput.value,
    })
  }

  /**
   * Handle password change
   */
  const handlePasswordChange = async (event: InputCustomEvent) => {
    // Browser autofill bug workaround
    const nativeInput = await event.target.getInputElement()

    setCredentials({
      ...credentials,
      password: event.detail.value || nativeInput.value,
    })
  }

  /**
   * Handle form submit
   */
  const handleSubmit: JSX.EventHandler<JSX.TargetedEvent<HTMLFormElement>> = event => {
    if (event instanceof SubmitEvent) {
      event.preventDefault()
    }

    setIsAuthenticating(true)

    // Note: Don't change state on success as it leads to component unmount
    api.signIn(credentials)
      .catch(error => {
        setError(error)
        setIsAuthenticating(false)
      })
  }

  /**
   * Submit form on enter key
   * Browser don't submit forms when more than 1 input is present (HTML 2.0 spec)
   * @see https://github.com/ionic-team/ionic-framework/issues/19368
   * Note: tabindex on ion-button isn't supported
   */
  const handleFormKeyDown: JSX.EventHandler<JSX.TargetedEvent<HTMLFormElement, KeyboardEvent>> = event => {
    if (
      event.target instanceof HTMLInputElement &&
      event.key === 'Enter' &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey
    ) {
      // Emulate submit button click (constraint validation)
      event.target.form!.requestSubmit()
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div className="syn-center-wrapper syn-gray-background">
          <IonCard className="ion-text-center syn-center-item">
            {/** Logo */}
            <div className="syn-logo-container ion-margin">
              <Logo />
              <IonText className="syn-headline">
                { appDescription }
              </IonText>
            </div>
            {/** Loading indicator */}
            <IonProgressBar
              type="indeterminate"
              style={{ visibility: isAuthenticating ? 'visible' : 'hidden' }}
            />
            <IonCardHeader>
              <IonCardTitle>
                {'Sign in'}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <form
                ref={formRef}
                onKeyDown={handleFormKeyDown}
                onSubmit={handleSubmit}
              >
                <IonList lines="none">
                  {/** Username */}
                  <IonItem>
                    <IonInput
                      autocomplete="username"
                      autofocus={true}
                      disabled={isAuthenticating}
                      enterkeyhint="send"
                      id="username"
                      inputmode="email"
                      label="Email"
                      labelPlacement="stacked"
                      name="username"
                      placeholder={'Syngeos account username or email'}
                      required={true}
                      type="email"
                      value={credentials.username}
                      onIonChange={handleUsernameChange}
                    />
                  </IonItem>

                  {/** Password */}
                  <IonItem>
                    <IonInput
                      autocomplete="current-password"
                      disabled={isAuthenticating}
                      enterkeyhint="send"
                      id="password"
                      inputmode="text"
                      label="Password"
                      labelPlacement="stacked"
                      name="password"
                      placeholder={'Syngeos account password'}
                      required={true}
                      type="password"
                      value={credentials.password}
                      onIonChange={handlePasswordChange}
                    />
                  </IonItem>
                </IonList>

                {/** Submit */}
                <IonButton
                  className="ion-margin-vertical"
                  disabled={isAuthenticating}
                  expand="block"
                  tabIndex={submitButtonTabIndex}
                  type="submit"
                >
                  {'Sign in'}
                </IonButton>

                {/** Error */}
                {error &&
                  <IonText
                    className="ion-margin-vertical"
                    color="danger"
                  >
                    { error instanceof OAuth2Error
                      ? error.description
                      : error.message
                    }
                  </IonText>
                }
              </form>
            </IonCardContent>
          </IonCard>
          <Footer appVersion={appVersion} />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SignInPage
