import type { TAction } from '../state.types'
import type { TSettings } from './settings.types'
import TYPE from './settings.actiontypes'

export default function reducer (state: TSettings, action: TAction): TSettings {
  switch (action.type) {
    case TYPE.SET:
      return action.payload
  }

  return state
}
