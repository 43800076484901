import type { TUiState } from './ui.types'

const now = new Date()

const initialState: TUiState = {
  dateRange: {
    // First day of previous month + first day of current month. Use users' Time Zone
    start: (new Date(now.getFullYear(), now.getMonth() - 1, 1)).getTime(),
    end:   (new Date(now.getFullYear(), now.getMonth(),     1)).getTime(),
  },
  tplFileHandle: null,
}

export default initialState
