import { useState } from 'preact/hooks'
import { IonRouterLink } from '@ionic/react'

import './Footer.css'

const Footer: preact.FunctionComponent<{
  appVersion: string,
  buildMode?: 'production' | 'development' | string,
}> = ({
  appVersion,
  buildMode = import.meta.env.MODE,
}) => {
  const [ isServiceMenuOpen, setIsServiceMenuOpen ] = useState<boolean>(false)

  const handleFooterClick = (): void => {
    if (buildMode === 'development') {
      setIsServiceMenuOpen(!isServiceMenuOpen)
    }
  }

  return (
    <footer className="syn-footer ion-text-right">
      <div onClick={handleFooterClick}>
        {appVersion} ({buildMode})
      </div>
      {/** Service menu */}
      {isServiceMenuOpen &&
        <ul className="syn-footer__service-menu">
          <li>
            <IonRouterLink href={`?${toggleUspValue('disable-mocks')}`}>
              {'Toggle mock service worker'}
            </IonRouterLink>
          </li>
        </ul>
      }
    </footer>
  )
}

/**
 * Toggle parameter in URL Search params on or off
 */
function toggleUspValue(param: string): URLSearchParams {
  const urlSearchParams = new URLSearchParams(window.location.search)

  if (urlSearchParams.has(param)) {
    urlSearchParams.delete(param)
  } else {
    urlSearchParams.set(param, 'true')
  }

  return urlSearchParams
}

export default Footer
