import { useState } from 'preact/hooks'
import {
  IonList,
  IonReorderGroup,
  IonReorder,
  IonItem,
  IonInput,
  IonCheckbox,
} from '@ionic/react'
import type {
  InputCustomEvent,
  CheckboxCustomEvent,
  ItemReorderCustomEvent,
} from '@ionic/react'

import type {
  TColumnKey,
  TColumn,
  TColumns,
} from '../../state/report'
import COLUMN from '../../constants/column'
import useToast from '../../hooks/useToast'
import ModalWrapper from '../ModalWrapper/ModalWrapper'

import './ColumnMapper.css'

// Define UI item
type TItem = {
  key: TColumnKey,
  label: string | null,
  isEnabled: boolean,
}

// UI labels
const itemLabelsMap = new Map<TColumnKey, string>([
  [COLUMN.DATE,          'Date'],
  [COLUMN.LABEL,         'Label'],
  [COLUMN.PM2_5,         'PM 2.5'],
  [COLUMN.PM10,          'PM 10'],
  [COLUMN.PM1,           'PM 1'],
  [COLUMN.SO2,           'SO2'],
  [COLUMN.NO2,           'NO2'],
  [COLUMN.CO,            'CO'],
  [COLUMN.O3,            'O3'],
  [COLUMN.C6H6,          'C6H6'],
  [COLUMN.TEMPERATURE,   'Temperature'],
  [COLUMN.AIR_PRESSURE,  'Air pressure'],
  [COLUMN.HUMIDITY,      'Humidity'],
  [COLUMN.CAQI,          'CAQI'],
])

/**
 * Prevent disabling these columns
 */
const preventToggle: TColumnKey[] = [
  COLUMN.LABEL,
  COLUMN.DATE,
]

/**
 * Column mapper
 */
const ColumnMapper: preact.FunctionComponent<{
  columns: TColumns,
  onApply: (columns: TColumns) => void,
  onDismiss: () => void,
}> = ({
  columns,
  onApply,
  onDismiss,
}) => {
  const [ items, setItems ] = useState<TItem[]>(() => columns)

  const showToast = useToast()

  /**
   * Handle item label change
   */
  const handleItemLabelChange = (event: InputCustomEvent, key: TColumnKey): void =>
    setItems(items.map(item =>
      item.key === key
        ?
          {
            ...item,
            label: event.detail.value || null
          }
        : item
    ))

  /**
   * Handle item toggle
   */
  const handleItemToggle = (event: CheckboxCustomEvent<TColumnKey>): void =>
    setItems(items.map(item =>
      item.key === event.detail.value
        ?
          {
            ...item,
            isEnabled: event.detail.checked
          }
        : item
    ))

  /**
   * Handle reorder
   */
  const handleReorder = (event: ItemReorderCustomEvent): void =>
    setItems(
      event.detail.complete(items)
    )

  /**
   * Handle save click
   */
  const handleSaveClick = (): void => {
    // Validate
    if (!items.every((item): item is TColumn => item.label !== null)) {
      showToast('Error: Missing label', { color: 'danger'})
      return
    }

    onApply(items)
  }

  return (
    <ModalWrapper
      title={'Columns (Station ID/ Label, Enable)'}
      onSave={handleSaveClick}
      onCancel={onDismiss}
    >
      <IonList>
        <IonReorderGroup
          disabled={false}
          onIonItemReorder={handleReorder}
        >
          {items.map(item =>
            <IonItem key={item.key} lines="full">
              <IonInput
                name="label"
                type="text"
                value={item.label ?? ''}
                placeholder={'Custom label'}
                slot="start"
                label={itemLabelsMap.get(item.key) ?? item.key}
                labelPlacement="fixed"
                onIonChange={(event: InputCustomEvent) => handleItemLabelChange(event, item.key)}
              />
              <IonCheckbox
                name="enabled"
                value={item.key}
                checked={item.isEnabled}
                disabled={preventToggle.includes(item.key)}
                slot="end"
                aria-label="Enabled"
                onIonChange={(event: CheckboxCustomEvent) => handleItemToggle(event)}
              />
              <IonReorder slot="end" />
            </IonItem>
          )}
        </IonReorderGroup>
      </IonList>
    </ModalWrapper>
  )
}

export default ColumnMapper
