import { useState, useEffect } from 'preact/compat'
import {
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonInput,
  IonReorderGroup,
  IonReorder,
} from '@ionic/react'
import type { InputCustomEvent, ItemReorderCustomEvent } from '@ionic/react'
import {
  addSharp,
  addOutline,
  trashSharp,
  trashOutline,
} from 'ionicons/icons'

import type { TIdMapItems } from '../../state/report'
import useToast from '../../hooks/useToast'
import ModalWrapper from '../ModalWrapper/ModalWrapper'

type TUiStationId = number | null
type TUiExternalId = string | null

/**
 * UI item which allows nulls for any value
 */
type TNewItem = {
  stationId: TUiStationId,
  externalId: TUiExternalId,
}

/**
 * ID Mapper component
 */
const IdMapper: preact.FunctionComponent<{
  idmap: TIdMapItems,
  onApply: (map: TIdMapItems) => void,
  onDismiss: () => void,
}> = ({
  idmap,
  onApply,
  onDismiss,
}) => {
  // Items copy
  const [ items, setItems ] = useState<TIdMapItems>(idmap)

  // New item
  const [ newItem, setNewItem ] = useState<TNewItem>({
    stationId: null,
    externalId: null,
  })

  // Toast hook
  const showToast = useToast()

  // Handle updates from parent
  useEffect(() => {
    setItems(items)
  }, [idmap]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handle exsting item value change
   */
  const handleItemLabelChange = (event: InputCustomEvent, index: number): void => {
    event.preventDefault()

    const updatedItems = items.map((item, cmpIndex) =>
      cmpIndex === index
        ? {
            ...item,
            externalId: parseLabel(event.detail.value!),
          }
        : item
    )

    setItems(updatedItems)
  }

  /**
   * Handle item remove
   */
  const handleItemRemove = (event: React.MouseEvent, index: number): void =>
    setItems(items.filter((item, cmpIndex) =>
      cmpIndex !== index
    ))

  /**
   * Handle reonder
   */
  const handleReorder = (event: ItemReorderCustomEvent): void =>
    setItems(
      event.detail.complete(items)
    )

  /**
   * Handle new item station ID change
   */
  const handleNewItemStationIdChange = (event: InputCustomEvent): void => {
    event.preventDefault()

    setNewItem({
      ...newItem,
      stationId: parseStationId(event.detail.value!),
    })
  }

  /**
   * Handle new item label change
   */
  const hadleNewItemLabelChange = (event: InputCustomEvent): void => {
    event.preventDefault()

    setNewItem({
      ...newItem,
      externalId: parseLabel(event.detail.value!),
    })
  }

  /**
   * Handle new item button click
   */
  const handleNewItemAdd = (): void => {
    const { stationId, externalId } = newItem

    // Validate station ID
    if (stationId === null) {
      showToast('Error: Missing station ID', { color: 'danger' })
      return
    }

    // Validate unique station IDs
    const stationIds = items.map(item => item.stationId)

    if (stationIds.includes(stationId)) {
      showToast('Error: Station ID already present', { color: 'danger' })
      return
    }

    setItems([
      ...items,
      { stationId, externalId },
    ])

    setNewItem({ stationId: null, externalId: null })
  }

  /**
   * Handle save button click
   */
  const handleSaveClick = (): void => {
    onApply(items)
  }

  return (
    <ModalWrapper
      title={'ID map (Station ID, Label)'}
      onSave={handleSaveClick}
      onCancel={onDismiss}
    >
      <IonList lines="full">
        {/** Available items */}
        <IonReorderGroup
          disabled={false}
          onIonItemReorder={handleReorder}
        >
          {/** Note: When Not using index as a key, IonInput loses focus */}
          {items.map((item, index) =>
            <IonItem key={item.stationId}>
              {/** Station ID */}
              <IonInput
                type="number"
                value={item.stationId}
                required
                step="1"
                min={1}
                disabled
                aria-label="Station ID"
              />
              {/** Label */}
              <IonInput
                type="text"
                value={item.externalId ?? ''}
                placeholder={item.stationId ? `Label (${item.stationId})`: 'Label'}
                aria-label="Label"
                onIonChange={(event: InputCustomEvent) => handleItemLabelChange(event, index)}
              />
              {/** Remove button */}
              <IonButton
                type="button"
                fill="clear"
                size="small"
                onClick={(event: React.MouseEvent) => handleItemRemove(event, index)}
              >
                <IonIcon
                  md={trashSharp}
                  ios={trashOutline}
                  slot="icon-only"
                />
              </IonButton>
              <IonReorder />
            </IonItem>
          )}
        </IonReorderGroup>

        {/** New item */}
        <IonItem>
          {/** Station ID */}
          <IonInput
            type="number"
            value={newItem.stationId?.toString() ?? ''}
            required
            placeholder={'Station ID'}
            aria-label="Station ID"
            onIonChange={(event: InputCustomEvent) => handleNewItemStationIdChange(event)}
          />
          {/** Label */}
          <IonInput
            type="text"
            value={newItem.externalId ?? ''}
            placeholder={newItem.stationId ? `Label (${newItem.stationId})` : 'Label'}
            aria-label="Label"
            onIonChange={(event: InputCustomEvent) => hadleNewItemLabelChange(event)}
          />
          {/** Add button */}
          <IonButton
            type="button"
            fill="clear"
            size="small"
            expand="block"
            onClick={handleNewItemAdd}
            style={{
              '--padding-start': '22px',
              '--padding-end': '22px',
            }}
          >
            <IonIcon
              md={addSharp}
              ios={addOutline}
              slot="icon-only"
            />
          </IonButton>
        </IonItem>
      </IonList>
    </ModalWrapper>
  )
}

export default IdMapper

/**
 * Parse station ID (number)
 */
function parseStationId(value: string): TUiStationId {
  if (value === '') {
    return null
  }

  const stationId = Number.parseInt(value, 10)

  return Number.isNaN(stationId)
    ? null
    : stationId
}

/**
 * Parse label (string)
 */
function parseLabel(value: string): TUiExternalId {
  if (value === '') {
    return null
  }

  return value
}
