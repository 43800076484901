import type { TAppState, TAction } from '../state.types'
import { initialState } from '../state'
import TYPE from './persist.actiontypes'

export default function reducer (state: TAppState = initialState, action: TAction): TAppState {
  switch (action.type) {
    case TYPE.REHYDRATE:
      return {
        ...state,
        ...action.payload,
      }
  }

  return state
}
