import { useState } from 'preact/hooks'
import pLimit from 'p-limit'
import {
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonIcon,
  IonPage,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonProgressBar,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  useIonModal,
} from '@ionic/react'
import type { InputCustomEvent } from '@ionic/react'
import {
  logOutSharp,
  logOutOutline,
  cogSharp,
  cogOutline,
  stopSharp,
  stopOutline,
} from 'ionicons/icons'

import { useAppState } from '../../state/AppStateContext'
import { useApi } from '../../api/ApiContext'

import type { TIdMapItem } from '../../state/report'
import {
  setIdMap,
  setColumns,
  setReportType,
} from '../../state/report'
import { setDateRange } from '../../state/ui'
import { setSettings } from '../../state/settings'

import { getData } from '../../data/provider'
import type { TOutputRows } from '../../data/converter'
import convert from '../../data/converter'
import { parse as csvParse, stringify as csvStringify } from '../../utils/csv'

import Logo from '../../components/Logo/Logo'
import IdMapper from '../../components/IdMapper/IdMapper'
import ColumnMapper from '../../components/ColumnMapper/ColumnMapper'
import DateRange from '../../components/DateRange/DateRange'
import ReportType from '../../components/ReportType/ReportType'
import Template from '../../components/Template/Template'
import DownloadButton from '../../components/DownloadButton/DownloadButton'
import PopoverWrapper from '../../components/PopoverWrapper/PopoverWrapper'

/** Processsing progress */
type TProcessingProgress = {
  /** Done progress in scale of 0..1 */
  progress: number,
  /** Working progress in scale of 0..1 */
  buffer: number,
}

/** Processing item wrapper */
type TProcessItem = {
  idMapItem: TIdMapItem,
  abortController: AbortController,
}

/**
 * Dashboard page
 */
const DashboardPage: preact.FunctionComponent = () => {
  // Configuration
  const maxConcurrency = 10

  // State
  const { state, dispatch } = useAppState()
  const api = useApi()

  const [ isProcessing, setIsProcessing ] = useState<boolean>(false)
  const [ processingProgress, setProcessingProgress ] = useState<TProcessingProgress>({
    progress: 0,
    buffer: 0,
  })

  // Reference to abort controller for each item
  const [ processItems, setProcessItems ] = useState<TProcessItem[]>([])

  const [ error, setError ] = useState<Error|null>(null)
  const [ downloadBody, setDownloadBody ] = useState<string|null>(null)

  const [ presentIdMapper, dismissIdMapper ] = useIonModal(IdMapper, {
    idmap: state.report.idmap,
    onApply: (idmap: typeof state.report.idmap) => {
      dispatch(setIdMap(idmap))
      dismissIdMapper()
    },
    onDismiss: () => dismissIdMapper(),
  })

  const [ presentColumnMapper, dismissColumnMapper ] = useIonModal(ColumnMapper, {
    columns: state.report.columns,
    onApply: (columns: typeof state.report.columns) => {
      dispatch(setColumns(columns))
      dismissColumnMapper()
    },
    onDismiss: () => dismissColumnMapper()
  })

  /**
   * Handle submit
   */
  const handleSubmit = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault()

    // Reset progress
    setProcessingProgress({ progress: 0, buffer: 0 })

    // Validate form
    if (!state.report.idmap.length) {
      setError(new Error('ID Map is empty'))
      return
    }

    setError(null)
    setIsProcessing(true)

    // Create concurrency limit promise wrapper to limit concurrency to max stations count
    const limitConcurrency = Math.min(state.settings.concurrency, state.report.idmap.length) || 1
    const limit = pLimit(limitConcurrency)

    // Wrap id map item with abort controller
    const processItems: TProcessItem[] = state.report.idmap.map(idMapItem => ({
      idMapItem,
      abortController: new AbortController(),
    }))

    setProcessItems(processItems)

    // Omit disabled columns
    const enabledColumns = state.report.columns.filter(column => column.isEnabled)

    // Handle stations (Async)
    const processPromises = processItems.map((item): Promise<TOutputRows> =>
      limit(async ({ idMapItem, abortController }): Promise<TOutputRows> => {
        let responseText: string|null

        try {
          responseText = await getData(
            idMapItem.stationId,
            enabledColumns,
            state.ui.dateRange,
            state.report.type,
            api,
            abortController.signal
          )
        } catch (error) {
          // Skip silently on abort errors
          // These may be caused either by user action or fetch failure
          if (error instanceof DOMException && error.name === 'AbortError') {
            return []
          }

          throw error
        }

        // No-op on empty report
        if (responseText === null) {
          return []
        }

        // Parse
        const [ inputHeader, ...inputRows ] = csvParse(responseText.trimEnd())

        // Convert
        return convert(
          enabledColumns,
          // Use station id when external ID is N/A
          idMapItem.externalId ?? idMapItem.stationId.toString(),
          inputHeader as string[],
          inputRows,
        )
      }, item)
    )

    // Set buffer on request start
    setProcessingProgress({
      progress: 0,
      buffer: limitConcurrency / state.report.idmap.length,
    })

    // Update progress
    for (const promise of processPromises) {
      promise
        // Mute console error `Uncaught (in promise)` on chained promise
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
        .finally(() => {
          // Finished + active
          const startedCount = state.report.idmap.length - limit.pendingCount

          const progress = (startedCount - limit.activeCount) / state.report.idmap.length
          const buffer = startedCount / state.report.idmap.length

          setProcessingProgress({ progress, buffer })
        }
      )
    }

    let results: TOutputRows[]

    // Resolve results and handle errors
    try {
      results = await Promise.all(processPromises)
    } catch (error) {
      // Abort pending requests
      // Note: It's not possible to determine promise state without using Promise.race so aborting all
      processItems.forEach(({ abortController }) => abortController.abort())

      // Disacard pending promises, which are aborted anyway
      limit.clearQueue()

      setError(error instanceof Error
        ? error
        : new Error('Unknown error')
      )

      return
    } finally {
      setIsProcessing(false)
      setProcessItems([])
    }

    // Concatenate results into flat table
    const outputRows: TOutputRows = results.reduce(
      (acc, stationOutputRows) => acc.concat(stationOutputRows),
      []
    )

    // No data
    if (!outputRows.length) {
      return setError(new Error('No data'))
    }

    const outputHeader = enabledColumns.map(column => column.label)

    // Stringify
    const data = csvStringify([
      outputHeader,
      ...outputRows,
    ])

    // Prepare data url
    const blob = new Blob([data], { type: 'text/csv' })
    const urlData = URL.createObjectURL(blob)

    setDownloadBody(urlData)
  }

  /**
   * Handle cancel click
   * Note: Abort errors are muted and partial report may be available to download
   */
  const handleCancelClick = () => {
    processItems.forEach(({ abortController }) => abortController.abort())
    setProcessItems([])
  }

  /**
   * Note: onIonChange fires event after state has been updated when value prop ain't string
   */
  const handleConcurrencyChange = (event: InputCustomEvent): void =>
    dispatch(setSettings({
      ...state.settings,
      concurrency: Math.max(1, Math.min(maxConcurrency, Number.parseInt(event.detail.value!, 10))),
    }))

  /**
   * Handle download button click
   */
  const handleDownloadClick = (): void => {
    if (!downloadBody) {
      return
    }

    // Cleanup memory
    if (downloadBody.search(/^blob:/) !== -1) {
      URL.revokeObjectURL(downloadBody)
    }

    setDownloadBody(null)

    // Reset progress
    setProcessingProgress({ progress: 0, buffer: 0 })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Logo toolbar={true} />
          </IonButtons>
          {/** Android */}
          <IonTitle>
            {'Syngeos Reports'}
          </IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={() => api.signOut()}>
              <IonIcon
                slot="icon-only"
                md={logOutSharp}
                ios={logOutOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/** iOS */}
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {'Dashboard'}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="syn-center-wrapper syn-gray-background">
          <IonCard className="syn-center-item">
            {/** Progress bar indicator */}
            <IonProgressBar
              value={processingProgress.progress}
              buffer={isProcessing ? processingProgress.buffer : 1}
            >
              {processingProgress.progress * 100}%
            </IonProgressBar>

            <IonCardHeader>
              <IonCardTitle>
                <IonLabel>
                  {'Create new report'}
                </IonLabel>
                <PopoverWrapper id="templates-context-menu-trigger">
                  <Template />
                </PopoverWrapper>
              </IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <form>
                <IonList lines="full">
                  {/** Report properties */}
                  <IonListHeader color="light">
                    <IonLabel>
                      {'Report properties'}
                    </IonLabel>
                  </IonListHeader>

                  {/** ID Mapper */}
                  <IonItem>
                    <IonLabel>
                      {'ID map'}
                    </IonLabel>
                    <IonButton
                      fill="outline"
                      size="small"
                      slot="end"
                      onClick={() => presentIdMapper() }
                    >
                      {state.report.idmap.length} items
                    </IonButton>
                  </IonItem>

                  {/** Columns mapper */}
                  <IonItem>
                    <IonLabel>
                      {'Columns'}
                    </IonLabel>
                    <IonButton
                      fill="outline"
                      size="small"
                      slot="end"
                      onClick={() => presentColumnMapper()}
                    >
                      {state.report.columns.filter(column => column.isEnabled).length} items
                    </IonButton>
                  </IonItem>

                  {/** Date range */}
                  <IonItem>
                    <IonLabel>
                      {'Date range'}
                    </IonLabel>
                    <DateRange
                      value={state.ui.dateRange}
                      onChange={value => dispatch(setDateRange(value))}
                    />
                  </IonItem>

                  {/** Report type */}
                  <IonItem>
                    <ReportType
                      value={state.report.type}
                      onChange={value => dispatch(setReportType(value))}
                    />
                  </IonItem>

                  {/** Processing settings */}
                  <IonListHeader color="light">
                    <IonLabel>
                      {'Settings'}
                    </IonLabel>
                  </IonListHeader>

                  {/** Concurrency limit */}
                  <IonItem>
                    <IonLabel>
                      {'Concurrency'}
                    </IonLabel>
                    <IonInput
                      type="number"
                      value={state.settings.concurrency.toString()}
                      autocomplete="off"
                      required={true}
                      min={1}
                      max={maxConcurrency}
                      step="1"
                      slot="end"
                      aria-label="Concurrency"
                      onIonChange={handleConcurrencyChange}
                    />
                  </IonItem>
                </IonList>

                {/** Submit button */}
                <IonButton
                  type="button"
                  disabled={isProcessing}
                  onClick={handleSubmit}
                >
                  <IonIcon
                    md={cogSharp}
                    ios={cogOutline}
                    slot="start"
                  />
                  {'Create'}
                </IonButton>

                {/** Cancel button */}
                <IonButton
                  type="button"
                  color="light"
                  disabled={!processItems.length}
                  onClick={handleCancelClick}
                >
                  <IonIcon
                    md={stopSharp}
                    ios={stopOutline}
                    slot="start"
                  />
                  {'Cancel'}
                </IonButton>

                {/** Download button */}
                <DownloadButton
                  data={downloadBody}
                  filename="report.csv"
                  onClick={handleDownloadClick}
                />
              </form>

              {/** Error */}
              {error &&
                <IonItem lines="none">
                  <IonText color="danger">
                    {'Error'}: {error.message}
                  </IonText>
                </IonItem>
              }
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default DashboardPage
