import { useMemo } from 'preact/hooks'
import type { DatetimeCustomEvent } from '@ionic/react'
import {
  IonDatetime,
  IonDatetimeButton,
  IonModal,
} from '@ionic/react'

import type { TDateRange } from './../../state/ui'

import { formatDateTimeIso8601 } from '../../utils/date'

import './DateRange.css'

/**
 * Date range contorlled form component
 */
const DateRange: preact.FunctionComponent<{
  value: TDateRange,
  onChange: (value: TDateRange) => void,
}> = ({
  value,
  onChange,
}) => {
  // Convert value to ISO 8601
  const valueFormatted = useMemo(
    () => ({
      start: formatDateTimeIso8601(new Date(value.start)),
      end:   formatDateTimeIso8601(new Date(value.end)),
      now:   formatDateTimeIso8601(new Date()),
    }),
    [value.start, value.end]
  )

  /**
   * Handle start date change
   * Note: Fired when using time picked and defaults buttons are not shown
   *       To fix add showDefaultButtons prop to IonDateTime
   */
  const handleStartDateChange = async (event: DatetimeCustomEvent) => {
    // Close modal; Using confirm triggers infinite lopp in ionic v7
    await event.target.cancel(true)

    // Skip null/ multiple values
    if (typeof event.detail.value === 'string') {
      onChange({
        ...value,
        start: Date.parse(event.detail.value),
      })
    }
  }

  /**
   * Handle end date change
   */
  const handleEndDateChange = async (event: DatetimeCustomEvent) => {
    // Close modal; Using confirm triggers infinite lopp in ionic v7
    await event.target.cancel(true)

    // Skip null/ multiple values
    if (typeof event.detail.value === 'string') {
      onChange({
        ...value,
        end: Date.parse(event.detail.value!),
      })
    }
  }

  return (
    <div
      className="syn-daterange"
      slot="end"
    >
      <IonDatetimeButton
        datetime="syn-datetime-start"
      />

      <IonModal keepContentsMounted>
        <IonDatetime
          id="syn-datetime-start"
          firstDayOfWeek={1}
          locale={'en-GB'}
          value={valueFormatted.start}
          max={valueFormatted.end}
          minuteValues={0}
          presentation="date"
          size="cover"
          onIonChange={handleStartDateChange}
        >
          <div slot="title">{'Start date'}</div>
        </IonDatetime>
      </IonModal>

      <span>
        {'–'}
      </span>

      <IonDatetimeButton
        datetime="syn-datetime-end"
      />

      <IonModal keepContentsMounted>
        <IonDatetime
          id="syn-datetime-end"
          firstDayOfWeek={1}
          locale={'en-GB'}
          value={valueFormatted.end}
          min={valueFormatted.start}
          max={valueFormatted.now}
          minuteValues={0}
          presentation="date"
          size="cover"
          onIonChange={handleEndDateChange}
        >
          <div slot="title">{'End date'}</div>
        </IonDatetime>
      </IonModal>

    </div>
  )
}

export default DateRange
